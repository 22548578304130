<template>
  <div>
    <br />
    <div
      class="app-page-spinner"
      v-if="loading"
      v-loading="loading"
    ></div>

    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-file
        :label="fields.finalReportFile.label"
        :value="presenter(record, 'finalReportFile')"
        :permissions="
          fields.finalReportFile.permissions
        "
      ></app-view-item-file>

      <app-view-item-file
        :label="fields.finalProjectBudgetAndFunding.label"
        :value="presenter(record, 'finalProjectBudgetAndFunding')"
        :permissions="
          fields.finalProjectBudgetAndFunding.permissions
        "
      ></app-view-item-file>
    </el-form>
  </div>
</template>w

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectCompletionModel } from '@/modules/project-completion/project-completion-model';

const { fields } = ProjectCompletionModel;

export default {
  name: 'app-project-completion-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectCompletion/view/record',
      loading: 'projectCompletion/view/loading',
      projectCompletionId: 'project/form/projectCompletionId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectCompletionId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectCompletion/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectCompletionModel.presenter(record, fieldName);
    },
  },
};
</script>
